<template>
  <DoubleColumnLayout>
    <template v-slot:default>

      <v-container fluid class="full-height">

          <h1>{{ $t("checkoutSuccess.caption") }}</h1>
          <br>
          {{ $t("checkoutSuccess.thankYou") }}
          <br>
          <br>
          {{ $t("checkoutSuccess.sentInstructions") }}
          <br>
          <br>
          {{ $t("checkoutSuccess.checkSpam") }}

      </v-container>

    </template>

    <template v-slot:right>
    </template>

  </DoubleColumnLayout>
</template>

<script>
import DoubleColumnLayout from "@/components/DoubleColumnLayout.vue";
export default {
  name: "CheckoutSuccess",
  components: {
    DoubleColumnLayout,
  },
};
</script>