<template>
  <DoubleColumnLayout>
    <template v-slot:default>

      <v-container>

          <v-alert dismissible type="error" v-model="paymentError">
            {{ $t("checkout.paymentError") }}
          </v-alert>

          <h1>{{ $t("checkout.caption") }}</h1>

          <table style="margin-top: 20px; margin-bottom: 20px;">
            <tr>
              <td style="width: 100px;">{{ $t("checkout.product") }}</td>
              <td>{{ productInfo.appName + ' - ' + productInfo.productName  }}</td>
            </tr>
            <tr>
              <td>{{ $t("checkout.price") }}</td>
              <td>{{ (productInfo.price / 100).toLocaleString(undefined, { minimumFractionDigits: 2 }) + ' ' + productInfo.currency  }}</td>
            </tr>
          </table>

          <v-form ref="form" v-model="formValid">
            <v-text-field
                v-model="customerEmail"
                :label="$t('checkout.email.label')"
                :hint="$t('checkout.email.hint')"
                persistent-hint
                required
                :rules="emailRules"
                :counter="EMAIL_MAX_LENGTH"
            />
          </v-form>
          <div id="paypal-button-container" style="margin-top: 20px;"></div>

      </v-container>

    </template>

    <template v-slot:right>
    </template>

  </DoubleColumnLayout>
</template>

<script>
import DoubleColumnLayout from "@/components/DoubleColumnLayout.vue";
export default {
  name: "Checkout",
  components: {
    DoubleColumnLayout,
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      EMAIL_MAX_LENGTH: 255,
      customerEmail: null,
      formValid: true,
      emailRules: [
        (v) => !!v || this.$t("checkout.email.rules.empty"),
        (v) =>
          (v && v.length <= this.EMAIL_MAX_LENGTH) ||
          this.$t("checkout.email.rules.maxLength", [this.EMAIL_MAX_LENGTH]),
        (v) => /.+@.+\..+/.test(v) || this.$t("checkout.email.rules.invalid"),
      ],
      paymentError: false,
      productInfo: {
        appName: "",
        productName: "",
        price: 0,
        currency: ""
      }
    };
  },
  methods: {
    async fetchData() {
      const response = await this.$api.get(
        "app/product/" + this.$route.params.productId + "?lang=" + this.$store.state.locale,
      );
      this.productInfo = await response.data;
    },
  },
  mounted() {
    let payPalSdk = document.createElement('script')
    payPalSdk.setAttribute('src', 'https://www.paypal.com/sdk/js?currency=EUR&client-id=' + process.env.VUE_APP_PAYPAL_CLIENT_ID)

    let controller = this;

    payPalSdk.onload = function() {
        paypal.Buttons({
            createOrder: function (data, actions) {
                controller.paymentError = false;
                controller.$refs.form.validate();
                if (!controller.formValid) {
                  return null;
                }
                let creationRequest = {
                    "productId": controller.$route.params.productId,
                    "customerEmail": controller.customerEmail
                }
                return fetch(process.env.VUE_APP_API_BASE_URL + '/api/v1/pay-pal/create-order', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(creationRequest)
                }).then(function(res) {
                    return res.json();
                }).then(function(data) {
                    return data.id;
                });
            },
            onApprove: function (data, actions) {
              return fetch(process.env.VUE_APP_API_BASE_URL + '/api/v1/pay-pal/capture-order/' + data.orderID, {
                  method: 'POST'
              }).then(function(res) {
                  if (res.ok) {
                    controller.$router.push({ name: 'CheckoutSuccess'});
                  }
                  else {
                    alert('Something went wrong');
                  }
              });
            },
            onError: function (err)  {
              if (controller.formValid) {
                // don't show error if form is invalid
                controller.paymentError = true;
              }
            }
        }).render('#paypal-button-container');
    }

    document.head.appendChild(payPalSdk);
   },
};

</script>